import { OutlinedButtonBlack, SecondaryButton } from '@truphone/buttons'
import React from 'react'

export default function IoTPlans(props) {
  return (
    <div className="mx-auto max-w-790 bg-white rounded shadow-lg -mt-4 lg:-mt-10 mb-10 relative z-1">
      <div className="p-8 flex flex-wrap">
        <div className="w-full md:w-3/4 pb-6 md:pb-0 md:pr-10">
          {props.topTitle && <p className="overline-muted mb-1">{props.topTitle}</p>}
          {props.title && <h2 className="text-3xl mb-2 font-semibold">{props.title}</h2>}
          {props.subtitle && <p className="text-xs text-grey-700">{props.subtitle}</p>}
        </div>
      </div>
      <div className="w-full border-b border-grey-200"></div>
      <div className="p-8 flex flex-wrap">
        <div className="w-full md:w-1/3 ">
          <h3 className="text-2xl mb-2">
            {props.data}{' '}
            <span className="text-sm">
              {'/ '}
              {props.sim}
            </span>
          </h3>
          <p className="text-sm font-medium mb-2">{props.duration}</p>
          <p className="text-xs text-grey-700">{props.description}</p>
        </div>
        {props.ctas && (
          <div className="block w-full flex-wrap mt-4 lg:mt-0 md:w-2/3 md:flex justify-center md:items-end md:justify-end -mx-2">
            {props.ctas.map((cta, i) => {
              return i === 0 ? (
                <OutlinedButtonBlack
                  className={`w-full md:w-auto m-2 md:px-2 md:text-base`}
                  key={`cta-button-${i}`}
                  href={cta.href}
                >
                  {cta.text}
                </OutlinedButtonBlack>
              ) : (
                <SecondaryButton
                  className={`w-full md:w-auto m-2 md:px-2 md:text-base`}
                  key={`cta-button-${i}`}
                  href={cta.href}
                >
                  {cta.text}
                </SecondaryButton>
              )
            })}
          </div>
        )}
      </div>
      <div className="w-full border-b border-grey-200"></div>
      <div
        className="text-sm px-8 py-4 bg-grey-100 link-blue"
        dangerouslySetInnerHTML={{ __html: props.textBottom }}
      />
    </div>
  )
}
